import logo from "./logo.svg";
import "./App.css";
import React from "react";
import { Environment, newPoodle } from "@beagleservices/dogwalker";

const OUTLINE = {
  display: "flex",
  flexDirection: "column",
};

const INLINE = {
  margin: "auto",
};

const CLICK = {
  cursor: "pointer",
};

class App extends React.Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <div style={OUTLINE}>
            <div style={INLINE}>
              <PoodleButton partner="DELTA" />
            </div>
            <div style={INLINE}>
              <PoodleButton partner="MASOV" />
            </div>
            <div style={INLINE}>
              <PoodleButton partner="CHUBB" />
            </div>
            <div style={INLINE}>
              <PoodleButton partner="CHUBB_CLAIMS" />
            </div>
            <div style={INLINE}>
              <PoodleButton partner="CHUBB_INTERNAL" />
            </div>
            <div style={INLINE}>
              <PoodleButton partner="TIBER_DEWINTER" />
            </div>
            <div style={INLINE}>
              <PoodleButton partner="PURE" />
            </div>
            <div style={INLINE}>
              <PoodleButton partner="CINFIN" />
            </div>
            <div style={INLINE}>
              <PoodleButton partner="OMIG" />
            </div>
            <div style={INLINE}>
              <PoodleButton partner="FPL" />
            </div>
            <div style={INLINE}>
              <PoodleButton partner="BEAGLE" region="CA" />
            </div>
            <div style={INLINE}>
              <PoodleButton partner="BEAGLE" subscription={true} />
            </div>
            <div style={INLINE}>
              <PoodleButton partner="BEAGLE" special="GODZILLA" />
            </div>
            <div style={INLINE}>
              <PoodleButton partner="BEAGLE" manage={true} />
            </div>
            <div style={INLINE}>
              <PoodleButton partner="BEAGLE" landlord={true} />
            </div>
            <div style={INLINE}>
              <PoodleButton partner="BEAGLE" internal={true} />
            </div>
            <div style={INLINE}>
              <PoodleButton
                partner="BEAGLE"
                internal={true}
                internalCreate={true}
              />
            </div>
            <div style={INLINE}>
              <PoodleButton partner="BEAGLE" product={true} />
            </div>
            <div style={INLINE}>
              <PoodleButton partner="BEAGLE" reschedule={true} />
            </div>
            <div style={INLINE}>
              <PoodleButton openWithOptions={true} />
            </div>
            <div style={INLINE}>
              <PoodleButton partner="CHUBB_NOTION_NO_INSTALL" />
            </div>
            <div style={INLINE}>
              <PoodleButton partner="AAA" />
            </div>

            <div style={INLINE}>
              <Floki />
            </div>

            <div style={INLINE}>
              <ChowChow />
            </div>
          </div>
        </header>
      </div>
    );
  }
}

class ChowChow extends React.Component {
  handleOpen = () => {
    const origin = window.location.origin;

    let url;
    if (origin === "http://localhost:3001") {
      url = "http://localhost:3000/proactive";
    } else if (origin === "https://localhost:3001") {
      url = "https://localhost:3000/proactive";
    } else {
      url = "https://dev-poodle.beagleservices.com/proactive?environment=dev";
    }

    window.open(url, "_blank");
  };

  render() {
    return (
        <div>
        <span onClick={this.handleOpen} style={CLICK}>
          Open ChowChow
        </span>
        </div>
    );
  }
}

class Floki extends React.Component {
  handleOpen = () => {
    const origin = window.location.origin;

    let url;
    if (origin === "http://localhost:3001") {
      url = "http://localhost:3000/?floki=true&partner=BEAGLE";
    } else if (origin === "https://localhost:3001") {
      url = "https://localhost:3000/?floki=true&partner=BEAGLE";
    } else {
      url = "https://dev-poodle.beagleservices.com/admin?environment=dev";
    }

    window.open(url, "_blank");
  };

  render() {
    return (
      <div>
        <span onClick={this.handleOpen} style={CLICK}>
          Open Floki Dashboard
        </span>
      </div>
    );
  }
}

class PoodleButton extends React.Component {
  constructor(props) {
    super(props);
    const { partner } = props;
    this.state = {
      loaded: false,
      poodle: undefined,

      productOffering: "WATER_HEATER",
      zipCode: "92626",

      partner,
      jobId: "",
      customerId: "",
    };
  }

  handleProductChanged = (product) => {
    this.setState({ productOffering: product });
  };

  handleZipChanged = (zip) => {
    this.setState({ zipCode: zip });
  };

  handlePartnerChanged = (partner) => {
    this.setState({ partner });
  };

  handleJobIdChanged = (job) => {
    this.setState({ jobId: job });
  };

  handleCustomerIdChanged = (customer) => {
    this.setState({ customerId: customer });
  };

  createPoodle = () => {
    const {
      region,
      subscription,
      special,
      manage,
      landlord,
      internal,
      internalCreate,
      reschedule,
      product,
      openWithOptions,
    } = this.props;
    const origin = window.location.origin;

    const { partner, customerId, jobId, zipCode, productOffering } = this.state;

    let debug;
    let environment;
    if (origin === "http://localhost:3001") {
      environment = Environment.LOCAL;
      debug = true;
    } else if (origin === "https://localhost:3001") {
      environment = Environment.LOCAL;
      debug = true;
    } else {
      environment = Environment.DEV;
      debug = true;
    }
    const poodle = newPoodle();
    poodle.install({
      environment,
      partner,
      region,
      special,
      productOffering: product ? productOffering : undefined,
      debug: !!debug,
      landlord: !!landlord,
      manage: !!manage,
      subscription: !!subscription,
      internal: !!internal,
      internalCreate: !!internalCreate,
      job: openWithOptions ? undefined : reschedule ? jobId : undefined,
      zipCode: openWithOptions ? undefined : reschedule ? zipCode : undefined,
      customer: openWithOptions
        ? undefined
        : customerId
        ? customerId
        : undefined,
      onInstalled: () => {
        this.setState({ loaded: true });
      },
    });
    this.setState({ poodle });
  };

  handleClick = () => {
    const { openWithOptions } = this.props;
    const { poodle, loaded } = this.state;
    const { jobId, zipCode, customerId } = this.state;
    if (!poodle) {
      this.createPoodle();
    } else {
      if (loaded) {
        poodle.open(
          openWithOptions
            ? {
                job: jobId,
                zip: zipCode,
                customer: customerId,
              }
            : undefined
        );
      }
    }
  };

  render() {
    const { poodle, loaded } = this.state;
    const {
      region,
      subscription,
      special,
      manage,
      landlord,
      product,
      internal,
      internalCreate,
      reschedule,
      openWithOptions,
    } = this.props;
    const { partner, customerId, jobId, zipCode, productOffering } = this.state;
    return (
      <div>
        <span onClick={this.handleClick} style={CLICK}>
          {!poodle ? "(CREATE)" : loaded ? "" : "(LOADING)"}&nbsp;Poodle
          for&nbsp;
        </span>
        Partner: {partner}&nbsp;
        {!!region && ` in Region: ${region}`}
        {!!subscription && ` with Subscription`}
        {!!special && ` with Special=${special}`}
        {!!manage && ` with Manage=${manage}`}
        {!!landlord && ` with Landlord=${landlord}`}
        {!!product && (
          <React.Fragment>
            <span>
              WITH PRODUCT&nbsp;
              <Input
                value={productOffering}
                onChange={this.handleProductChanged}
              />
            </span>
          </React.Fragment>
        )}
        {!!internal && ` with INTERNAL MODE=${internal}`}
        {!!internalCreate && ` with INTERNAL_CREATE MODE=${internalCreate}`}
        {(!!reschedule || !!openWithOptions) && (
          <React.Fragment>
            {openWithOptions && <span>ON LAUNCH&nbsp;</span>}
            <span>
              WITH PARTNER&nbsp;
              <Input value={partner} onChange={this.handlePartnerChanged} />
            </span>
            <span>
              WITH JOB&nbsp;
              <Input value={jobId} onChange={this.handleJobIdChanged} />
            </span>
            <span>
              WITH CUSTOMER&nbsp;
              <Input
                value={customerId}
                onChange={this.handleCustomerIdChanged}
              />
            </span>
            <span>
              WITH ZIP_CODE&nbsp;
              <Input value={zipCode} onChange={this.handleZipChanged} />
            </span>
          </React.Fragment>
        )}
      </div>
    );
  }
}

class Input extends React.Component {
  handleChange = ($event) => {
    const { onChange } = this.props;
    onChange($event.target.value || "");
  };

  render() {
    const { value, onChange, ...rest } = this.props;
    return <input {...rest} value={value} onChange={this.handleChange} />;
  }
}

export default App;
