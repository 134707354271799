/**
 * Internal poodle commands
 *
 * To be use with messages from a child iframe to a parent window
 */
import { LIBRARY_NAME, LIBRARY_VERSION } from "../../lib";
import { Environment } from "../../Environment";
import { PoodleGutsOptions } from "../../poodle/PoodleGutsOptions";

export enum InternalPoodleCommands {
  CLOSE = "INTERNAL_CLOSE",
}

/**
 * Get the iframe ID
 *
 * @param id
 * @param environment
 * @param options
 */
export function getPoodleIframeId(
  id: string,
  environment: Environment,
  options: PoodleGutsOptions
): string {
  const core: string[] = [LIBRARY_NAME, LIBRARY_VERSION, id, environment];
  const { environment: ignoreMe, ...rest } = options;
  const runtime: string[] = Object.keys(rest).map((k: string) => {
    const key = k as keyof PoodleGutsOptions;
    return `${options[key]}`;
  });
  return [...core, ...runtime].join("-");
}
