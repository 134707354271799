import { IframeInstaller } from "../install/IframeInstaller";
import { Pupper } from "../bark/Pupper";
import { PoodleGutsOptions } from "./PoodleGutsOptions";

export class PoodleInstaller extends IframeInstaller<PoodleGutsOptions> {
  /**
   * Override
   * @param id
   * @param url
   * @param options
   */
  async install(
    id: string,
    url: string,
    options: PoodleGutsOptions
  ): Promise<void> {
    const logger = new Pupper("poodle/PoodleInstaller", options.debug);

    const params = new URLSearchParams();

    for (const k of Object.keys(options)) {
      const key = k as keyof PoodleGutsOptions;
      params.set(key, `${options[key]}`);
    }

    logger.d("Install Poodle into iframe: ", {
      params: params.toString(),
    });

    return this.installIframe(id, url, params, options, { show: false });
  }
}
