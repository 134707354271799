import { Guts } from "../dog/Guts";
import { Pupper } from "../bark/Pupper";
import { Messenger } from "../message/Messenger";
import { MessengerOptions } from "../message/MessengerOptions";
import { Environment } from "../Environment";
import { PoodleGutsOptions } from "./PoodleGutsOptions";
import { InternalPoodleCommands } from "../internal/poodle/InternalPoodleCommands";
import { PoodleMessages } from "./PoodleMessages";
import { Listener } from "../Listener";
import {
  asMessageId,
  POODLE_INTERNAL_MESSAGE_ID,
} from "../internal/poodle/PoodleMessageId";

/**
 * Internal poodle command handler
 */
export abstract class PoodleGuts implements Guts {
  /**
   * Logger
   */
  protected readonly logger: Pupper;

  /**
   * The messenger implementation
   */
  protected readonly parentMessenger: Messenger<MessengerOptions>;

  /**
   * Track debug state
   */
  protected isDebug: boolean;

  /**
   * For message ID
   */
  protected messageSubscription: boolean;
  protected messageRegion: string;
  protected messagePartner: string;

  /**
   * Track environment
   */
  protected environment: Environment;

  protected constructor(messenger: Messenger<MessengerOptions>) {
    this.messagePartner = "";
    this.messageRegion = "";
    this.messageSubscription = false;
    this.isDebug = false;
    this.environment = Environment.PROD;

    this.parentMessenger = messenger;
    this.logger = new Pupper("poodle/InternalPoodle");
  }

  /**
   * Create the message ID
   */
  protected createMessageId(id: string): string {
    return asMessageId(id, {
      environment: this.environment,
      partner: this.messagePartner,
      region: this.messageRegion,
      subscription: this.messageSubscription,
      debug: this.isDebug,
    });
  }

  /**
   * Install the internals
   * @param options
   */
  public install(options: PoodleGutsOptions) {
    this.messagePartner = options.partner;
    this.messageRegion = options.region;
    this.messageSubscription = options.subscription;

    this.isDebug = options.debug;
    this.environment = options.environment;
    this.logger.setDebug(this.isDebug);
  }

  /**
   * Override
   */
  public close(): void {
    const origin = "*";
    const message = {
      id: this.createMessageId(POODLE_INTERNAL_MESSAGE_ID),
      name: InternalPoodleCommands.CLOSE,
      msg: undefined,
    };

    const options = {
      debug: this.isDebug,
    };

    this.logger.d("Post close message out to parent window", {
      environment: this.environment,
      message,
      origin,
      options,
    });

    this.parentMessenger.postMessage(message, origin, options);
  }

  /**
   * Listen for messages from the App hosting side
   */
  public abstract listenForMessages(listener: PoodleMessages): Listener;
}
